// sconst TIME_ZONE_GROUPS = {
//   1: [2, 3],
//   2: [4, 5],
//   3: [7, 8, 9],
//   4: [11, 12],
// };
const SIRIUS_ORG_IDS = [9765, 10916];
/**
 * Договорились, что временную группу мероприятий,
 * будем зашивать в название ивента в формате `Временна зона 1`
 * @param {string} eventName
 * @returns {string} Временная зона
 */
// const parseTimeZOneGroup = (eventName) => {
//   const regex = /временная зона\s?(\d{1,2})/i;
//   const match = regex.exec(eventName);
//   if (!match) return false;
//   return match[1];
// };

/**
 * Возвращает мероприятие, по региону пользователя или по его таймзоне
 * @param {Event[]} events
 * @param {UserEducation} educationобразования
 * @returns {Object} matched event
 */
export const getJuniorFinalEvent = (listEvents = [], education) => {
  const events = listEvents.filter(Boolean);
  if (!events.length) return;
  const regionWithType = education?.region_with_type;
  const federalDistrict = education?.federal_district;
  const orgId = education?.organization;

  const isSiriusEdu = !!orgId && SIRIUS_ORG_IDS.includes(orgId);
  const onlineEvent = events.find((event) => {
    return event.format === "online";
  });

  if (isSiriusEdu) {
    const siriusEvent = events.find(({ title }) =>
      title?.toLowerCase().includes("сириус")
    );
    if (siriusEvent) return siriusEvent;
  }

  if (!regionWithType) {
    return onlineEvent;
  }
  /**
   * Пытаемся найти offline мероприятие у которого регион
   * проведения соответствует региону пользователя,
   * из списка регионов нужно исключить сириус,
   * т.к туда регистрируются только школьники сириуса
   */
  const region = regionWithType.toLowerCase();

  const sameRegionFinalEvent = events.find(
    (event) => event.region_with_type?.toLowerCase() === region
  );
  if (sameRegionFinalEvent) return sameRegionFinalEvent;

  const isAbroad = education?.country !== "Россия";

  if (!isAbroad && federalDistrict) {
    const disrtict = federalDistrict.toLowerCase();
    const offlineEvent = events.find(
      (n) =>
        n.federal_district?.toLowerCase() === disrtict && !n.region_with_type
    );
    if (offlineEvent) return offlineEvent;
  }

  return onlineEvent;
};
