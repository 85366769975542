<template>
  <div class="container">
    <RoleCard theme="student">
      <template #label>Приглашение в профиль</template>
      <template #label_img>
        <img
          src="@/assets/images/reg_school.svg"
          alt="иконка" />
      </template>
      <template #body>
        <div class="row">
          <div class="col-md-8 col-sm-12 col-xs-12">
            <div
              v-if="error"
              class="mb-m">
              <p>Не удалось принять приглашение.</p>
              <p class="form-error">{{ error }}</p>
            </div>
            <div v-if="currentProfile">
              <div class="mb-l">
                Вас приглашают принять участие в профиле
                {{ currentProfile.title }}
              </div>

              <BaseButton @click.prevent="handleAccept">Принять</BaseButton
              >&nbsp;
              <BaseButton
                theme="reg"
                @click.prevent="handleReject"
                >Отклонить</BaseButton
              >
            </div>
            <BaseSpinner
              v-else
              brand />
          </div>
        </div>
      </template>
    </RoleCard>
  </div>
</template>

<script>
/**
 * @page InviteProfile
 * Страница инвайта в конкретный профиль.S
 */
import { mapGetters } from "vuex";
import RoleCard from "@/components/registration/RoleCard.vue";
import { setUTMCookie, eraseCookie } from "@/utils/cookies";
import { request } from "@/services/api";
import { getJuniorFinalEvent } from "@/utils/participant";
import { FINAL_STAGE } from "@/constants";
export default {
  name: "InviteProfile",
  components: { RoleCard },
  metaInfo() {
    return {
      title: `Приглашение участника принять участие в НТО в профиль ${
        this.currentProfile?.title || this.$route.query.profile_id
      }`,
    };
  },
  data() {
    return {
      pending: true,
      error: "",
      currentProfile: null,
    };
  },
  computed: {
    ...mapGetters({
      selectedList: "participant/selectedList",
      isJunior: "participant/isJunior",
      currentEducation: "participant/currentEducation",
      user: "user/user",
    }),
    requiredFinalEvent() {
      return this.isJunior && this.user?.participant?.grade > 4;
    },
    events() {
      return this.$store.state.profile.events;
    },
    finalEvent() {
      const { currentProfile, events } = this;
      if (!currentProfile) return;
      const eventList = currentProfile.steps
        ?.filter((step) => step.stage === FINAL_STAGE && step.talent_event_id)
        .map((step) => {
          return events[step.talent_event_id];
        });
      return getJuniorFinalEvent(eventList, this.currentEducation);
    },
  },
  async created() {
    this.pending = true;
    const id = +this.$route.query.profile_id;
    const isSelected = this.selectedList.find((n) => n.profile_id === id);
    // если профиль уже выбран или его нельзя выбрать то перекидываем
    if (isSelected || !this.$store.state.profile.profiles[id]) {
      this.$router
        .push({
          name: "user-index",
        })
        .catch(() => {
          // do noting
        });
      return;
    }

    try {
      const { data } = await request({
        method: "GET",
        url: `/profiles/${this.$route.query.profile_id}`,
      });
      this.currentProfile = data;
      setUTMCookie(this.$route.query);
    } catch (error) {
      this.error = "Профиль не найден";
    }

    if (this.requiredFinalEvent) {
      try {
        await this.$store.dispatch("user/getEducation", true).catch((error) => {
          console.log("error", error);
        });
        await this.$store
          .dispatch("profile/getAllFinalEvents")
          .catch((error) => {
            console.log("error", error);
          });
      } catch (error) {
        console.log("error", error);
      }
    }

    this.pending = false;
  },
  methods: {
    async handleAccept() {
      try {
        const pPayload = {
          profile_id: +this.$route.query.profile_id,
        };
        if (this.requiredFinalEvent) {
          if (!this.finalEvent) {
            throw {
              message: "Финал данной сферы не проводится в вашем регионе",
            };
          } else {
            pPayload.final_event = this.finalEvent?.id;
          }
        }
        await this.$store.dispatch("participant/selectProfile", pPayload);
        eraseCookie("utm");
        this.$router.push({
          name: "user-index",
        });
      } catch (error) {
        this.error = error.message;
      }
    },
    handleReject() {
      eraseCookie("utm");
      this.$router.push({
        name: "user-index",
      });
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .base-btn {
  min-width: 140px;
}
</style>
